<template>
    <div class="modal-real-friend-chat-alert">
        <div class="head">
            <div class="title">{{ title }}</div>
        </div>

        <main class="body">
            <ul class="list">
                <li v-for="content in contents" :key="content" class="item">
                    <p class="content" v-html="content" />
                </li>
            </ul>
        </main>

        <footer class="footer">
            <button @click="$emit('close')" class="btn">확인했어요</button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'ModalRealFriendChatAlert',
    props: {
        options: Object,
    },
    computed: {
        chat() {
            return this.options.chat
        },
        // 내친소에서 주선유저의 경우
        isAgentUser() {
            return this.chat.introduce.user_id === this.$store.getters.me.id
        },
        title() {
            return this.options.title
        },
        contents() {
            if (this.isAgentUser) {
                return [
                    '친구를 소개하는 것이 아니라, <strong>본인을 소개하거나 직접 만나려고 하는 경우</strong> 즉시 이용정지 처리될 수 있습니다.',
                    '<strong>연락처, SNS ID를 공유하는 경우</strong> 즉시 채팅이 종료될 수 있으며, 경우에 따라 이용정지 처리될 수 있습니다.',
                    '가벼운 목적을 완전히 차단하기 위해, <strong>첫 만남에 술자리를 갖는 것은 엄격하게 제한</strong>됩니다. 친구에게도 알려주세요!',
                ]
            } else if (!this.isAgentUser) {
                return [
                    '가벼운 목적을 완전히 차단하기 위해, <strong>첫 만남에 술자리를 갖는 것은 엄격하게 제한</strong>됩니다. 술자리를 제안했다가 만남이 이루어지지 않은 경우 절대 환급되지 않습니다.',
                    '<strong>주선자에게 관심을 보이거나 만나려고 하는 경우</strong> 즉시 이용정지 처리됩니다.',
                    '<strong>연락처, SNS ID를 공유하는 경우</strong> 즉시 채팅이 종료될 수 있으며, 경우에 따라 이용정지 처리될 수 있습니다.',
                ]
            }

            return []
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-real-friend-chat-alert {
    margin: 0 auto;
    padding: 0;
    width: 280px;

    .head {
        padding: 24px 16px 20px;
        color: $grey-09;
        font-size: 20px;
        line-height: normal;

        @include f-bold;
    }

    .list {
        padding: 0 16px 16px;
        border-bottom: solid 1px $grey-02;

        .item {
            padding: 10px 12px;
            border-radius: 10px;
            background-color: $grey-01;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .content {
            font-size: 13px;
            line-height: normal;

            ::v-deep strong {
                color: $pink-deep;
                font-weight: normal;

                @include f-bold;
            }
        }
    }

    .footer {
        height: 56px;

        @include center;

        .btn {
            color: $purple-primary;

            @include f-regular;
        }
    }
}
</style>
